import React, { useState } from 'react';
import { Button, Divider, Input, Typography, Collapse, Alert, Select } from 'antd';
import Form from 'components/Form';
import PhoneNumber from 'components/PhoneNumber';
import { getPhoneNumberValidator } from 'utils';
import { USA_STATES_DROPDOWN_OPTIONS } from 'constants';

const { Title, Text } = Typography;
const { Panel } = Collapse;

const ProviderAdvancedSearchForm = ({ state, form, onSubmit }) => {
  const [showAlert, setShowAlert] = useState(false);

  // Transform USA_STATES_DROPDOWN_OPTIONS to match the `options` format
  const options = USA_STATES_DROPDOWN_OPTIONS.map((state) => ({
    value: state.code,
    label: state.name,
  }));

  const handleFinish = (values) => {
    setShowAlert(false);
    const checks = [
      values.location?.address?.street,
      values.location?.address?.street2,
      values.location?.address?.city,
      values.location?.address?.postalCode,
      values.location?.states?.length > 0,
      values.careFacilityId,
      values.locationPhone,
      values.doingBusinessAs,
      values.businessLegalName,
    ];
    if (!checks.some((check) => check)) {
      setShowAlert(true);
      return;
    }

    // Proceed with submission
    onSubmit(values);
  };

  const resetSearch = () => {
    setShowAlert(false);
    form.resetFields();
    onSubmit({});
  };

  return (
    <Form form={form} layout="vertical" onFinish={handleFinish} data-testid="advanced-search-form" className="m-4">
      {showAlert && (
        <Alert
          message={<Title level={4}>Error</Title>}
          description="Enter one or more search criteria."
          type="error"
          showIcon={false}
          onClose={() => setShowAlert(false)}
          className="mb-5 py-2 px-4"
        />
      )}
      <Title level={4} className="mb-3">
        Advanced search
      </Title>
      <span>Search by one or all criteria.</span>

      <Collapse bordered={true} defaultActiveKey={[]} className="mt-8">
        <Panel header="Address" key="address">
          <Form.Item label="Address line 1" name={['location', 'address', 'street']}>
            <Input placeholder="" />
          </Form.Item>
          <Form.Item label="Address line 2" name={['location', 'address', 'street2']}>
            <Input placeholder="" />
          </Form.Item>
          <Form.Item label="City" name={['location', 'address', 'city']}>
            <Input placeholder="" />
          </Form.Item>
          <Form.Item label="State">
            <Text type="secondary">Select one or more.</Text>
            <Form.Item name={['location', 'states']} noStyle>
              <Select
                mode="multiple"
                placeholder="Select"
                options={options}
                maxTagCount={1}
                allowClear
                size="small"
                labelRender={(props) => {
                  // Find the state object from the selected value
                  const state = USA_STATES_DROPDOWN_OPTIONS.find((state) => state.code === props.value);
                  // Render the abbreviated state code (e.g., 'CA')
                  return <span>{state ? state.code : props.value}</span>;
                }}
              />
            </Form.Item>
          </Form.Item>
          <Form.Item label="Zip code" name={['location', 'address', 'postalCode']}>
            <Input placeholder="" />
          </Form.Item>
        </Panel>

        <Panel header="Facility ID" key="facilityId">
          <Form.Item label="Facility ID" name="careFacilityId">
            <Input placeholder="" />
          </Form.Item>
        </Panel>

        <Panel header="Phone number" key="locationPhone">
          <Form.Item
            label="Phone number"
            name="locationPhone"
            rules={[getPhoneNumberValidator('Invalid phone number!')]}
          >
            <PhoneNumber />
          </Form.Item>
        </Panel>

        <Panel header="Provider name" key="providerName">
          <Form.Item label="Doing business as (DBA)" name="doingBusinessAs">
            <Input placeholder="" />
          </Form.Item>
          <Form.Item label="Legal business name" name="businessLegalName">
            <Input placeholder="" />
          </Form.Item>
        </Panel>
      </Collapse>

      <Divider />

      <div className="actions">
        <Button type="default" onClick={resetSearch} data-testid="reset-button">
          Reset search
        </Button>
        <Button
          type="primary"
          htmlType="submit"
          loading={state.loading}
          disabled={state.disabled}
          className="ml-2"
          data-testid="search-button"
        >
          Search
        </Button>
      </div>
    </Form>
  );
};

export default ProviderAdvancedSearchForm;
