import axios from 'axios';
import sortBy from 'lodash/sortBy';
import config from 'config';
import { PROVIDER_STATUSES } from 'constants/index';
import { sleep } from 'utils';
import { setupCache } from './interceptors';

export const PROVIDER_FILE_TYPES = {
  STATE_LICENSE: { title: 'State license', code: 'LICENSE', required: true },
  HCC: { title: 'HCC', code: 'HCC', required: false },
  W9: { title: 'W-9', code: 'W9', required: true },
  RATE_SHEET: { title: 'Rate sheet', code: 'RATES', required: true },
  QRIS: { title: 'QRIS', code: 'QRIS', required: true },
  INSURANCE: { title: 'INSURANCE', code: 'INSURANCE', required: true },
  DIPLOMA: { title: 'DIPLOMA', code: 'DIPLOMA', required: true },
  CERTIFICATION: { title: 'CPR/first aid certification', code: 'CERTIFICATION', required: true },
  // optional files
  EIN: { title: 'EIN', code: 'EIN' },
  NATIONAL_ACCREDITATION: { title: 'National accreditation', code: 'ACCREDITATION' },
  DIRECT_DEPOSIT: { title: 'Direct deposit', code: 'DIRECT_DEPOSIT' },
  EDUCATION: { title: 'Continuing education', code: 'EDUCATION' },
  OTHER: { title: 'Other documents', code: 'OTHER' },
};
export const getProviderFileTypeFromCode = (code) => {
  const providerFileTypes = Object.values(PROVIDER_FILE_TYPES);
  return providerFileTypes.find((item) => item.code === code);
};
export const getProviderFileTypeTitleFromCode = (code) => {
  const providerFileTypes = Object.values(PROVIDER_FILE_TYPES);
  const providerFileType = providerFileTypes.find((item) => item.code === code);
  return providerFileType?.title;
};

const instance = axios.create({
  baseURL: `${config.API_BASE_URL}/providers`,
});
const request = setupCache(instance);

const api = {
  getCenters: async (statuses = []) => {
    const query = statuses.reduce((prev, status) => `${prev}${prev ? '&' : '?'}statuses=${status}`, '');
    const { data } = await request.get(`/care-facilities${query}`);
    return data;
  },
  getCenterById: async (id, options) => {
    const { data } = await request.get(`/care-facilities/${id}`, options);
    return data;
  },
  getAllCenter: async (params) => {
    const { data } = await request.get('/care-facilities/index', { params });
    return data;
  },
  getCareFacilities: async (orgId) => {
    const { data } = await request.get(`/organizations/${orgId}/care-facilities?size=20`);
    return data;
  },
  getStatusChangelog: async (id, applicationId) => {
    const { data } = await request.get(`/care-facilities/${id}/application/${applicationId}/status`);
    return data;
  },
  getCenterTypes: async () => {
    const { data } = await request.get('/options/8');
    return data;
  },
  getDiscountTypes: async () => {
    const { data } = await request.get('/options/12');
    return data;
  },
  updateCenter: async (params, id) => {
    const { data } = await request[id ? 'put' : 'post'](`/care-facilities${id ? `/${id}` : ''}`, params);
    return data;
  },
  submitApplication: async (appId) => {
    const { data } = await request.put(`/applications/${appId}/submit`);
    return data;
  },
  getPublicStatusList: async (statusType) => {
    const { data } = await request.get(`/options/3`);
    return data;
  },
  getApplicationStatus: async (id) => {
    const { data } = await request.get(`/application-statuses/${id}`);
    return data;
  },
  updateApplicationStatus: async (params) => {
    const { data } = await request.post(`/application-statuses/bulk`, params);
    return data;
  },
  assignCoordinator: async (params) => {
    const { data } = await request.post(`/applications/assign`, params);
    return data;
  },
  unAssignCoordinator: async (params) => {
    const { data } = await request.post(`/applications/unassign`, params);
    return data;
  },
  assignReviewer: async (params) => {
    const { data } = await request.post(`/applications/reviewer/assign`, params);
    return data;
  },
  getApplicationsByStatus: async (statuses = []) => {
    const query = statuses.reduce((prev, status) => `${prev}${prev ? '&' : '?'}status=${status}`, '');
    const { data } = await request.get(`/applications/byStatus${query}`);
    return data;
  },
  getCoordinatorApplicationsByStatus: async (statuses = []) => {
    const query = statuses.reduce((prev, status) => `${prev}${prev ? '&' : '?'}status=${status}`, '');
    const { data } = await request.get(`/applications/byAssignee${query}`);
    return data;
  },
  getApplicationsByReviewerStatus: async (statuses = []) => {
    const query = statuses.reduce((prev, status) => `${prev}${prev ? '&' : '?'}status=${status}`, '');
    const { data } = await request.get(`/applications/byReviewer${query}`);
    return data;
  },
  // TODO: This api has been removed
  getApplicationStatusCounts: async () => {
    const { data } = await request.get(`/application-statuses/count`);
    return data;
  },
  setApplicationPriority: async (id, priority) => {
    const { data } = await request.put(`/applications/${id}/priority?highPriority=${priority || false}`);
    return data;
  },
  getCoordinatorAssignments: async (statuses) => {
    const query = statuses.reduce((prev, status) => `${prev}${prev ? '&' : '?'}statuses=${status}`, '');
    const { data } = await request.get(`/applications/coordinators/stats${query}`);
    return data;
  },
  getBranches: async () => {
    const { data } = await request.get('/options/22');
    return data;
  },
  addStaff: async (id, params) => {
    const { data } = await request.post(`/care-facilities/${id}/staff`, params);
    return data;
  },
  getStaff: async (id) => {
    const { data } = await request.get(`/care-facilities/${id}/staff`);
    return data;
  },
  updateStaff: async (id, params, staffId) => {
    const { data } = await request.put(`/care-facilities/${id}/staff/${staffId}`, params);
    return data;
  },
  addUpdatedBgc: async (id, staffId, payload) => {
    const { data } = await request[payload.id ? 'put' : 'post'](
      `/care-facilities/${id}/staff/${staffId}/background-checks${payload.id ? `/${payload.id}` : ''}`,
      payload,
    );
    return data;
  },
  deleteStaff: async (id, staffId) => {
    const { data } = await request.delete(`/care-facilities/${id}/staff/${staffId}`);
    return data;
  },
  getStaffBackgroundChecks: async (id, staffId) => {
    const { data } = await request.get(`/care-facilities/${id}/staff/${staffId}/background-checks`);
    return data;
  },
  deleteStaffBackgroundCheck: async (id, staffId, bgcId) => {
    const { data } = await request.delete(`/care-facilities/${id}/staff/${staffId}/background-checks/${bgcId}`);
    return data;
  },
  getBgcChecklist: async () => {
    const { data } = await request.get('/checklist/background');
    return data;
  },
  getScheduleDetails: async (id) => {
    const { data } = await request.get(`/care-facilities/summary/${id}`);
    return data;
  },
  getProviderRequestDetails: async (id) => {
    const { data } = await request.get(`/care-facilities/requests/${id}`);
    return data;
  },
  deleteRequest: async (id) => {
    const { data } = await request.delete(`/care-facilities/requests/${id}`);
    return data;
  },
  updateChecklist: async (id, checklist) => {
    const { data } = await request.put(`/care-facilities/${id}/checklist`, checklist);
    return data;
  },
  getNotes: async (id, params) => {
    const { data } = await request.get(`/applications/${id}/notes`, { params });
    return data;
  },
  getOrganization: async () => {
    const { data } = await request.get('/organizations');
    return data;
  },
  getOrganizationById: async (orgId) => {
    const { data } = await request.get(`/organizations/providers/${orgId}`);
    return data;
  },
  getOrganizationValidity: async (orgId) => {
    const { data } = await request.get(`/organizations/${orgId}/profile/validate`);
    return data;
  },
  updateOrganization: async (id, params) => {
    const { data } = await request[id ? 'put' : 'post'](`/organizations/${id ? id : ''}`, params);
    return data;
  },
  getDraftNote: async (applicationId) => {
    const { data } = await request.get(`/applications/${applicationId}/notes?draft=true&sort=id,desc&page=0&size=1`);
    return data.content?.[0];
  },
  changeApplicationStatus: async (id, applicationId, statusId) => {
    const { data } = await request.put(
      `/care-facilities/${id}/application/${applicationId}/status?statusId=${statusId}`,
    );
    return data;
  },
  saveNote: async (applicationId, params) => {
    const { data } = await request[params.id ? 'put' : 'post'](
      `/applications/${applicationId}/notes/${params.id ? params.id : ''}`,
      params,
    );
    return data;
  },
  getPrograms: async (id) => {
    const { data } = await request.get(`/care-facilities/${id}/programs`);
    return data;
  },
  getRecentDocuments: async (id) => {
    const { data } = await request.get(`/care-facilities/${id}/documents`);
    return data;
  },
  updatePrograms: async (id, programs) => {
    const { data } = await request.put(`/care-facilities/${id}/programs`, programs);
    return data;
  },
  getStaffInfo: async (id, staffId) => {
    const { data } = await request.get(`/care-facilities/${id}/staff/${staffId}`);
    return data;
  },
  addBGC: async (id, staffId, backgroundChecks) => {
    const { data } = await request.post(`care-facilities/${id}/staff/${staffId}/background-checks`, backgroundChecks);
    return data;
  },

  updateBGC: async (id, staffId, bgcId, backgroundChecks) => {
    const { data } = await request.put(
      `care-facilities/${id}/staff/${staffId}/background-checks/${bgcId}`,
      backgroundChecks,
    );
    return data;
  },
  deleteBGC: async (id, staffId, bgcId) => {
    const { data } = await request.delete(`care-facilities/${id}/staff/${staffId}/background-checks/${bgcId}`);
    return data;
  },
  updateStaffBGC: async (id) => {
    await sleep(100);
    return [];
  },
  adjudicate: async (id, staffId, params) => {
    const { data } = await request.post(`/care-facilities/${id}/staff/${staffId}/adjudicate`, params);
    return data;
  },
  sendGenerateBackgroundChecklistEmail: async (id, backgroundChecklistEmailData) => {
    const { data } = await request.post(
      `/care-facilities/${id}/staff/checklist/email/send`,
      backgroundChecklistEmailData,
    );
    return data;
  },
  updateBackgroundChecksChecklist: async (id) => {
    await sleep(200);
    return [];
  },
  sendReplyEmail: async (id) => {
    await sleep(100);
    return [];
  },

  getOrigins: async () => {
    const { data } = await request.get('/options/16');
    return data;
  },
  getHelp: async (params) => {
    const response = await request.post(`/help`, params);
    const { data } = response;
    return data;
  },
  getProviders: async (params) => {
    const response = await request.get('/search', { params });
    const { data } = response;
    return data;
  },
  getPartnerAgencies: async () => {
    const response = await request.get('/partner-agencies');
    const { data } = response;
    return data;
  },
  setPartnerAgency: async (id, payload) => {
    const response = await request.post(`/care-facilities/${id}/partner-agencies`, payload);
    const { data } = response;
    return data;
  },
  addToWatcher: async (alertId, params) => {
    const response = await request.get(`/alerts/${alertId}/watchers`, { params });
    const { data } = response;
    return data;
  },
  removeWatchers: async (alertId, userIds) => {
    const response = await request.delete(`/alerts/${alertId}/watchers?userIds=${userIds}`);
    const { data } = response;
    return data;
  },
  updateWatchers: async (alertId, userIds) => {
    const response = await request.put(`/alerts/${alertId}/watchers?userIds=${userIds}`);
    const { data } = response;
    return data;
  },
  addProviderRequest: async (payload) => {
    const response = await request[payload.id ? 'put' : 'post']('/care-facilities/requests', payload);
    const { data } = response;
    return data;
  },
  getProviderStatus: async () => {
    // const response = await request.get('/provider/status', { params });
    // const { data } = response;
    const data = PROVIDER_STATUSES.ONBOARDING;
    return data;
  },

  getInsurances: async (id) => {
    const { data } = await request.get(`/care-facilities/${id}/liability-insurances`);
    return data;
  },
  addUpdateInsurance: async (centerId, payload) => {
    const { data } = await request[payload.id ? 'put' : 'post'](
      `/care-facilities/${centerId}/liability-insurances${payload.id ? `/${payload.id}` : ''}`,
      payload,
    );
    return data;
  },
  getTier: async (id) => {
    const { data } = await request.get(`/care-facilities/${id}/provider-tier`);
    return data || {};
  },
  deleteInsurance: async (centerId, id) => {
    const { data } = await request.delete(`/care-facilities/${centerId}/liability-insurances/${id}`);
    return data;
  },
  getAttendanceSheets: async (providerId, id, params) => {
    if (params.sort === 'submittedDate,asc') {
      return Promise.resolve(sortBy(ATTENDANCE_SHEETS, (v) => v.id));
    }
    return Promise.resolve(ATTENDANCE_SHEETS);
  },
  addUpdateAttendanceSheet: async (providerId, id) => {
    const { data } = await request.put(`/care-facilities/${id}/fees`);
    return data;
  },
  deleteAttendanceSheet: async (providerId, id) => {
    const { data } = await request.delete(`/care-facilities/${id}/fees`);
    return data;
  },
  acceptAgreement: async (appId, payload) => {
    const { data } = await request.post(`/applications/${appId}/agreement/accept`, payload);
    return data;
  },
  getApplicationAgreements: async (appId, options) => {
    const { data } = await request.get(`/applications/${appId}/agreements`, options);
    return data;
  },
  getDQReasons: async (id) => {
    await sleep(50);
    return REASONS;
  },
  /*
   * FILES
   */
  getCareFacilityFiles: async (id) => {
    const { data } = await request.get(`/care-facilities/${id}/files`);
    return data;
  },
  uploadCareFacilityFile: async (id, file, fileType) => {
    let formData = new FormData();
    formData.append('file', file);
    formData.append('fileType', fileType || PROVIDER_FILE_TYPES.OTHER.code);

    const { data } = await request.post(`/care-facilities/${id}/files`, formData);
    return data;
  },
  deleteCareFacilityFile: async (id, fileId) => {
    const { data } = await request.delete(`/care-facilities/${id}/files/${fileId}`);
    return data;
  },
  /*
   * POINT OF CONTACT
   */
  getAllApplicationPointsOfContact: async (careFacilityId) => {
    const { data } = await request.get(`/care-facilities/${careFacilityId}/point-of-contact`);
    return data;
  },
  addApplicationPointOfContact: async (careFacilityId, payload) => {
    const { data } = await request.post(`/care-facilities/${careFacilityId}/point-of-contact`, payload);
    return data;
  },
  updateApplicationPointOfContact: async (careFacilityId, payload) => {
    const { data } = await request.put(`/care-facilities/${careFacilityId}/point-of-contact/${payload.id}`, payload);
    return data;
  },
  deleteApplicationPointOfContact: async (careFacilityId, pocId) => {
    const { data } = await request.delete(`/care-facilities/${careFacilityId}/point-of-contact/${pocId}`);
    return data;
  },
  /*
   * FORGOT USERNAME
   */
  forgotUsername: async (payload) => {
    const { data } = await request.post('/forgot-username', payload);
    return data;
  },
  /*
   * ORG FILES
   */
  getOrgFiles: async (orgId) => {
    const { data } = await request.get(`/organizations/${orgId}/files`);
    return data;
  },
  uploadOrgFile: async (orgId, file, fileType) => {
    let formData = new FormData();
    formData.append('file', file);
    formData.append('fileType', fileType || PROVIDER_FILE_TYPES.OTHER.code);

    const { data } = await request.post(`/organizations/${orgId}/files`, formData);
    return data;
  },
  deleteOrgFile: async (orgId, fileId) => {
    const { data } = await request.delete(`/organizations/${orgId}/files/${fileId}`);
    return data;
  },
  /*
   * APPLICATIONS
   */
  createApplication: async (careFacilityId, payload) => {
    const { data } = await request.post(`/care-facilities/${careFacilityId}/applications`, payload);
    return data;
  },
  updateApplication: async (appId, payload) => {
    const { data } = await request.put(`/applications/${appId}`, payload);
    return data;
  },
  getApplication: async (appId) => {
    const { data } = await request.get(`/applications/${appId}`);
    return data;
  },
  getCarefacilityApplications: async (careFacilityId) => {
    const { data } = await request.get(`/care-facilities/${careFacilityId}/applications`);
    return data;
  },
  /*
   * Profile validity checks
   */
  getCareFacilityProfileValidity: async (careFacilityId) => {
    const { data } = await request.get(`/care-facilities/${careFacilityId}/profile/validate`);
    return data;
  },
  /*
   * APPLICATION FILES
   */
  getApplicationFiles: async (appId) => {
    const { data } = await request.get(`/applications/${appId}/files`);
    return data;
  },
  uploadApplicationFile: async (appId, file, fileType) => {
    let formData = new FormData();
    formData.append('file', file);
    formData.append('fileType', fileType || PROVIDER_FILE_TYPES.OTHER.code);

    const { data } = await request.post(`/applications/${appId}/files`, formData);
    return data;
  },
  deleteApplicationFile: async (appId, fileId) => {
    const { data } = await request.delete(`/applications/${appId}/files/${fileId}`);
    return data;
  },
  downloadAllApplicationFiles: async (appId) => {
    const { data } = await request.post(
      `/applications/${appId}/files/download`,
      {},
      {
        responseType: 'blob',
      },
    );
    return data;
  },
  /*
   * APPLICATION FEES
   */
  getFeeRates: async (id) => {
    const { data } = await request.get(`/applications/${id}/fees`);
    return data;
  },
  addUpdateFeeRate: async (applicationId, payload) => {
    const { data } = await request[payload.id ? 'put' : 'post'](
      `/applications/${applicationId}/fees${payload.id ? `/${payload.id}` : ''}`,
      payload,
    );
    return data;
  },
  deleteFeeRate: async (applicationId, id) => {
    const { data } = await request.delete(`/applications/${applicationId}/fees/${id}`);
    return data;
  },

  /*
   * APPLICATION DISCOUNTS
   */
  getApplicationDiscounts: async (id) => {
    const { data } = await request.get(`/applications/${id}/discounts`);
    return data;
  },
  addUpdateApplicationDiscounts: async (applicationId, payload) => {
    const { data } = await request[payload.id ? 'put' : 'post'](
      `/applications/${applicationId}/discounts${payload.id ? `/${payload.id}` : ''}`,
      payload,
    );
    return data;
  },
  deleteApplicationDiscounts: async (applicationId, id) => {
    const { data } = await request.delete(`/applications/${applicationId}/discounts/${id}`);
    return data;
  },

  /*
   * APPLICATION ACCREDITATIONS
   */
  getAccreditations: async (applicationId) => {
    const { data } = await request.get(`/applications/${applicationId}/accreditations`);
    return data;
  },

  addUpdateAccreditation: async (applicationId, payload) => {
    const { data } = await request[payload.id ? 'put' : 'post'](
      `/applications/${applicationId}/accreditations${payload.id ? `/${payload.id}` : ''}`,
      payload,
    );
    return data;
  },

  deleteAccreditation: async (applicationId, accreditationId) => {
    const { data } = await request.delete(`/applications/${applicationId}/accreditations/${accreditationId}`);
    return data;
  },

  /*
   * APPLICATION HOURS OF OPERATION
   */
  getHoursofOperation: async (applicationId) => {
    const { data } = await request.get(`/applications/${applicationId}/hours`);
    return data;
  },

  addUpdateHoursOfOperation: async (applicationId, payload) => {
    const { data } = await request[payload.id ? 'put' : 'post'](
      `/applications/${applicationId}/hours${payload.id ? `/${payload.id}` : ''}`,
      payload,
    );
    return data;
  },

  removeHoursOfOperation: async (applicationId, id) => {
    const { data } = await request.delete(`/applications/${applicationId}/hours/${id}`);
    return data;
  },

  /*
   * APPLICATION STAFF MEMBERS
   */
  getStaffMembers: async (applicationId) => {
    const { data } = await request.get(`/applications/${applicationId}/staff`);
    return data;
  },

  addUpdateStaffMembers: async (applicationId, payload) => {
    const { data } = await request[payload.id ? 'put' : 'post'](
      `/applications/${applicationId}/staff${payload.id ? `/${payload.id}` : ''}`,
      payload,
    );
    return data;
  },

  deleteStaffMember: async (applicationId, staffId) => {
    const { data } = await request.delete(`/applications/${applicationId}/staff/${staffId}`);
    return data;
  },

  addUpdateStaffMemberCert: async (applicationId, staffId, payload) => {
    const { data } = await request[payload.id ? 'put' : 'post'](
      `/applications/${applicationId}/staff${staffId ? `/${staffId}` : ''}/certifications${
        payload.id ? `/${payload.id}` : ''
      }`,
      payload,
    );
    return data;
  },

  deleteStaffMemberCert: async (applicationId, staffId, certId) => {
    const { data } = await request.delete(`/applications/${applicationId}/staff/${staffId}/certifications/${certId}`);
    return data;
  },

  /*
   * APPLICATION RESIDENTS
   */
  getResidents: async (applicationId) => {
    const { data } = await request.get(`/applications/${applicationId}/residents`);
    return data;
  },

  addUpdateResidents: async (applicationId, payload) => {
    const { data } = await request[payload.id ? 'put' : 'post'](
      `/applications/${applicationId}/residents${payload.id ? `/${payload.id}` : ''}`,
      payload,
    );
    return data;
  },

  deleteResident: async (applicationId, residentId) => {
    const { data } = await request.delete(`/applications/${applicationId}/residents/${residentId}`);
    return data;
  },

  getChildResidents: async (applicationId) => {
    const { data } = await request.get(`/applications/${applicationId}/child-residents`);
    return data;
  },

  addUpdateChildResidents: async (applicationId, payload) => {
    const { data } = await request[payload.id ? 'put' : 'post'](
      `/applications/${applicationId}/child-residents${payload.id ? `/${payload.id}` : ''}`,
      payload,
    );
    return data;
  },

  deleteChildResident: async (applicationId, residentId) => {
    const { data } = await request.delete(`/applications/${applicationId}/child-residents/${residentId}`);
    return data;
  },
};
export { request };
export default api;

const ATTENDANCE_SHEETS = [
  {
    id: 4,
    submittedDate: '2023-03-01T00:00:00',
    child: {
      id: 1,
      firstName: 'Harry',
      lastName: 'Porter',
    },
    certificate: {
      id: 123456,
    },
    status: {
      id: 1,
      title: 'Pending',
    },
  },
  {
    id: 3,
    submittedDate: '2023-03-01T00:00:00',
    child: {
      id: 1,
      firstName: 'Harry',
      lastName: 'Porter',
    },
    certificate: {
      id: 123456,
    },
    status: {
      id: 1,
      title: 'Paid',
    },
  },
  {
    id: 2,
    submittedDate: '2023-02-01T00:00:00',
    child: {
      id: 1,
      firstName: 'Harry',
      lastName: 'Porter',
    },
    certificate: {
      id: 123456,
    },
    status: {
      id: 1,
      title: 'Paid',
    },
  },
  {
    id: 1,
    submittedDate: '2023-01-01T00:00:00',
    child: {
      id: 1,
      firstName: 'Harry',
      lastName: 'Porter',
    },
    certificate: {
      id: 123456,
    },
    status: {
      id: 1,
      title: 'Paid',
    },
  },
];
const REASONS = [
  {
    reason: 'Religious License Exempt',
    id: 1,
  },
  {
    reason: 'Background Check',
    id: 2,
  },
  {
    reason: 'Child Care License Exempt',
    id: 3,
  },
  {
    reason: 'Non-US Citizen',
    id: 4,
  },
  {
    reason: 'Inspection report and disqualifying Incidents',
    id: 5,
  },
  {
    reason: "No Drivers's License",
    id: 6,
  },
  {
    reason: 'Expired Child Care License',
    id: 7,
  },
  {
    reason: 'Under Age 18',
    id: 8,
  },
  {
    reason: 'School Program is Run By Subcontractors',
    id: 9,
  },
  {
    reason: 'Fraud',
    id: 10,
  },
  {
    reason: 'No State Child Care License',
    id: 11,
  },
  {
    reason: 'Other',
    id: 12,
  },
];
