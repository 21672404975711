import { Tooltip } from 'antd';
import { Filter, FilterEdit } from '@carbon/icons-react';

export default function TableHeaderFilterIcon({ filtered, showTooltip }) {
  return (
    <Tooltip title={showTooltip === false ? undefined : 'Filters'}>
      <div>
        <button className="p-0 m-0 pointer-events-none">
          {filtered ? (
            <FilterEdit className="align-middle text-blue-600" />
          ) : (
            <Filter className="align-middle opacity-50" />
          )}
        </button>
      </div>
    </Tooltip>
  );
}
