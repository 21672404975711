import { useParams } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { Spin, Tabs, Card, Form, Typography, Row, Col } from 'antd';
import providerService from 'services/providers.service';
import PageMeta from 'components/PageMeta/PageMeta';
import ApplicationDetails from './ApplicationDetails';
import CareLocationDetails from './CareLocationDetails';
import DocumentDetails from './DocumentDetails';
import BusinessDetails from './BusinessDetails';
import Select from 'components/Select';
import { formatDate } from 'utils';
import { PROVIDER_APPLICATION_PROGRAM_TYPES } from 'containers/Provider/SelectProgram/SelectProgram';
import FormattedAddress from 'components/FormattedAddress/FormattedAddress';

const { Paragraph, Title, Link } = Typography;

const ProviderApplication = () => {
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [application, setApplication] = useState(null);
  const [form] = Form.useForm();

  useEffect(() => {
    setLoading(true);
    providerService
      .getApplication(id)
      .then((data) => {
        setApplication(data);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [id]);

  const handleReview = () => {};

  const getProgramNameById = (programId) => {
    const program = Object.values(PROVIDER_APPLICATION_PROGRAM_TYPES).find((program) => program.id === programId);
    return program ? program.name : 'N/A';
  };

  return (
    <Spin size="large" spinning={loading}>
      <div className="min-h-screen">
        <PageMeta title="Application Details" />

        {/* Header Section */}

        <Card className="mb-6">
          <Title level={1}>
            {application?.careFacility?.businessLegalName || application?.careFacility?.doingBusinessAs || 'N/A'}
          </Title>

          <Row gutter={[8, 8]} className="mt-4 w-3/4">
            <Col span={8}>
              <Paragraph className="!mb-1">
                <strong>Application ID:</strong> {application?.id}
              </Paragraph>
              <Paragraph className="!mb-1">
                <strong>Submitted:</strong> {formatDate(application?.submittedDate)}
              </Paragraph>
              <Paragraph className="!mb-1">
                <strong>Program:</strong> {getProgramNameById(application?.programType?.id)}
              </Paragraph>
            </Col>

            <Col span={8}>
              <Paragraph className="!mb-1">
                <strong>Care location address:</strong>
                <br />
                {application?.careFacility?.address ? (
                  <FormattedAddress address={application.careFacility.address} />
                ) : (
                  'N/A'
                )}
              </Paragraph>
            </Col>

            <Col span={8}>
              <Paragraph className="!mb-1">
                <strong>Application:</strong>{' '}
                {application?.careFacility?.organization?.businessContact?.displayName || 'N/A'}
                <br />
                {application?.careFacility?.organization?.businessContact?.position || 'N/A'}
                <br />
                {application?.careFacility?.organization?.businessContact?.email && (
                  <Link href={`mailto:${application.careFacility.organization.businessContact.email}`}>
                    {application.careFacility.organization.businessContact.email}
                  </Link>
                )}
              </Paragraph>
            </Col>
          </Row>
        </Card>

        {/* Main Section */}
        <div className="flex flex-col lg:flex-row gap-4">
          {/* Left Column */}
          <div className="lg:w-2/3 w-full">
            <Tabs
              defaultActiveKey="1"
              activeKey="4"
              items={[
                {
                  key: '1',
                  label: 'Application details',
                  children: <ApplicationDetails />,
                },
                {
                  key: '2',
                  label: 'Care location details',
                  children: <CareLocationDetails />,
                },
                {
                  key: '3',
                  label: 'Business details',
                  children: <BusinessDetails />,
                },
                {
                  key: '4',
                  label: 'Documents',
                  children: <DocumentDetails application={application} />,
                },
              ]}
              tabBarStyle={{
                marginBottom: '0px',
                border: 'none',
              }}
            />
          </div>

          {/* Right Column */}
          <div className="lg:w-1/3 w-full">
            <Card className="flex flex-col">
              <h4 className="mb-6">Review application</h4>
              <Form
                form={form}
                layout="vertical"
                onFinish={handleReview}
                data-testid="aprovider-application-review-form"
              >
                <Form.Item name="status" label="Status" rules={[{ required: true, message: 'Status is required.' }]}>
                  <Select
                    name="review-status"
                    options={[
                      { title: 'New', id: 'new' },
                      { title: 'Reviewed', id: 'reviewed' },
                      { title: 'Approved', id: 'approved' },
                    ]}
                    isOptionSelected={(opt, [value]) => opt?.id === value}
                    onChangeFormatter={(v) => v?.id}
                    ariaLabel="Review Status"
                  />
                </Form.Item>
                <Form.Item
                  name="assignee"
                  label="Assignee"
                  rules={[{ required: true, message: 'Assignee is required.' }]}
                >
                  <Select
                    name="application-assignee"
                    options={[]}
                    isOptionSelected={(opt, [value]) => opt?.id === value}
                    onChangeFormatter={(v) => v?.id}
                    ariaLabel="Application Assignee"
                  />
                </Form.Item>
              </Form>
            </Card>
          </div>
        </div>
      </div>
    </Spin>
  );
};

export default ProviderApplication;
