import { Launch } from '@carbon/icons-react';
import { message, Spin } from 'antd';
import { EMDASH } from 'constants';
import { useState } from 'react';
import { filesService } from 'services';

export const FileList = ({ files }) => {
  const [previewLoading, setPreviewLoading] = useState(null);

  if (!files || files.length === 0) {
    return EMDASH;
  }

  return (
    <div className="flex flex-col gap-2">
      {files?.map((file) => {
        const fileId = file.uid || file.id;

        return (
          <Spin spinning={previewLoading === fileId} key={fileId}>
            <a
              href="#"
              onClick={(e) => {
                e.preventDefault();

                showFilePreview(file, setPreviewLoading);
              }}
              className="flex items-center gap-2 underline"
            >
              <Launch />

              <span>{file.name}</span>
            </a>
          </Spin>
        );
      })}
    </div>
  );
};

export const showFilePreview = async (file, setLoading) => {
  const fileId = file.uid || file.id;
  setLoading?.(fileId);

  //Get file content data when clicked
  const fileData = await filesService.getFileContents(fileId);
  const url = `data:${fileData.contentType};base64,${fileData.contents}`;

  if (fileData) {
    setLoading?.(null);

    const newWindow = window.open();
    if (fileData.contentType.startsWith('image/')) {
      // Render images directly
      newWindow.document.write(
        `<html>
            <body style="margin:0;display:flex;align-items:center;justify-content:center;background-color:black;">
              <img src="${url}" style="max-width:100%; max-height:100%;" />
            </body>
          </html>`,
      );
    } else {
      // Render PDFs using the browser's native PDF viewer
      newWindow.document.write(
        `<html>
            <body style="margin:0;">
              <object data="${url}" type="application/pdf" width="100%" height="100%">
              </object>
            </body>
          </html>`,
      );
    }
  } else {
    message.error(`Unable to retrieve preview data for file: ${file.name}`);
  }
};
