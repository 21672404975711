import { FileList } from 'components/FileList/FileList';
import TabularList from 'components/TabularList/TabularList';
import { PROVIDER_FILE_TYPES } from 'services/providers.service';

export function MccynDocumentDetails({ applicationFiles, careFacilityFiles, orgFiles }) {
  const careFacilityAndOrgFiles = [...(careFacilityFiles || []), ...(orgFiles || [])];

  const _w9Files = careFacilityAndOrgFiles?.filter((file) => file?.fileType === PROVIDER_FILE_TYPES.W9.code);
  const _careLocationW9Files = careFacilityFiles?.filter((file) => file?.fileType === PROVIDER_FILE_TYPES.W9.code);
  const _einCertificateFiles = careFacilityAndOrgFiles?.filter(
    (file) => file?.fileType === PROVIDER_FILE_TYPES.EIN.code,
  );
  const _voidedCheckOrBankLetterFiles = careFacilityAndOrgFiles?.filter(
    (file) => file?.fileType === PROVIDER_FILE_TYPES.DIRECT_DEPOSIT.code,
  );
  const _licenseFiles = careFacilityFiles?.filter((file) => file?.fileType === PROVIDER_FILE_TYPES.STATE_LICENSE.code);
  const _accreditationFiles = applicationFiles?.filter(
    (file) => file?.fileType === PROVIDER_FILE_TYPES.NATIONAL_ACCREDITATION.code,
  );
  const _qrisFiles = careFacilityFiles?.filter((file) => file?.fileType === PROVIDER_FILE_TYPES.QRIS.code);
  const _rateSheetFiles = applicationFiles?.filter((file) => file?.fileType === PROVIDER_FILE_TYPES.RATE_SHEET.code);
  const _otherFiles = applicationFiles?.filter((file) => file?.fileType === PROVIDER_FILE_TYPES.OTHER.code);

  return (
    <TabularList
      className="mt-8"
      data={[
        {
          title: 'W-9',
          titleRequired: true,
          value: <FileList files={_w9Files} />,
        },
        {
          title: 'Care location W-9',
          titleRequired: true,
          value: <FileList files={_careLocationW9Files} />,
        },
        {
          title: 'EIN Certificate',
          value: <FileList files={_einCertificateFiles} />,
        },
        {
          title: 'Voided check or bank letter',
          value: <FileList files={_voidedCheckOrBankLetterFiles} />,
        },
        {
          title: 'Licenses',
          titleRequired: true,
          value: <FileList files={_licenseFiles} />,
        },
        {
          title: 'Accreditations',
          value: <FileList files={_accreditationFiles} />,
        },
        {
          title: 'QRIS',
          value: <FileList files={_qrisFiles} />,
        },
        {
          title: 'Rate sheet',
          titleRequired: true,
          value: <FileList files={_rateSheetFiles} />,
        },
        {
          title: 'Other documents',
          value: <FileList files={_otherFiles} />,
        },
      ]}
    />
  );
}
