import classNames from 'classnames';

export default function TabularList({ className, data }) {
  if (!data) {
    return null;
  }

  return (
    <div className={classNames('w-[450px] max-w-full flex-col', className)}>
      {data.map((d, rowIndex) => (
        <div
          key={typeof d?.title === 'string' ? d?.title : rowIndex}
          className={classNames(
            'border-b border-solid border-[#BFBFBF]',
            {
              'bg-[#FAFAFA]': rowIndex % 2 === 0,
            },
            'flex gap-4 divide-x divide-solid divide-[#BFBFBF]',
          )}
        >
          <div className="w-1/2 px-3 py-2 font-bold">
            {d?.titleRequired && <span className="text-red-500">*</span>}
            {d?.title}
          </div>
          <div className="w-1/2 px-3 py-2">{d?.value}</div>
        </div>
      ))}
    </div>
  );
}
