import React, { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import DashboardTable from '../Dashboard/DashboardTable';
import { actions } from 'features/family';
import { parseFamilyData } from 'utils';
import useTablePagination from 'hooks/useTablePagination';
import Card from 'components/Card/Card';

export default function FamilyApplications({ householdId }) {
  const history = useHistory();
  const dispatch = useDispatch();

  const columns = useMemo(
    () => [
      'caseId',
      'sponsor',
      'programType',
      'startDate',
      'endDate',
      'status',
      'daysInStatus',
      'assignedTo',
      'actions',
    ],
    [],
  );
  const params = useMemo(
    () => ({
      sort: `status.lastModifiedDate,asc`,
    }),
    [],
  );
  const state = useTablePagination({
    url: `/families/households/${householdId}/applications`,
    method: 'get',
    params,
    mapper: parseFamilyData,
    fetchOnMoun: true,
  });
  return (
    <Card noBodyPadding title="Applications">
      <DashboardTable
        onRow={(record) => {
          return {
            onClick: () => {
              history.push(`/families/${householdId}/applications/${record.id}`);
              dispatch(actions.setApplication({}));
            },
          };
        }}
        colKeys={columns}
        state={state}
        isFamilyDashbaord
        pinApplication={false}
        mapper={parseFamilyData}
        hideSelection
        className=""
      />
    </Card>
  );
}
