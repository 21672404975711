import React, { useMemo } from 'react';
import classNames from 'classnames';
import { Menu } from 'antd';
import { HiOutlineDotsVertical } from 'react-icons/hi';
import { useHistory, useLocation } from 'react-router-dom';
import { Modal } from 'antd';
import styles from './SidebarMenu.module.less';

export default function SidebarMenu({ className, menuOptions }) {
  const location = useLocation();
  const history = useHistory();

  const selectedKeys = useMemo(() => {
    const keys = [
      menuOptions.find((item) =>
        item?.match?.some((regex) =>
          typeof regex === 'string' ? location.pathname === regex : regex.test(location.pathname),
        ),
      )?.to,
    ];
    if (/\/applications\/\d+/.test(location.pathname)) {
      keys.push('/applications/root');
    }

    return keys;
  }, [location.pathname, menuOptions]);

  //This modal should only pop up when on the first page of the new location application
  const confirmExitModal = ({ key }) => {
    if (location.pathname === '/provider/facility') {
      Modal.confirm({
        title: 'Are you sure you want to exit?',
        okText: 'Exit without saving',
        cancelText: 'Stay',
        onOk: () => history.push(key),
      });
    } else {
      history.push(key);
    }
  };

  return (
    <div className={classNames(styles.menu, className)}>
      <Menu
        mode="vertical"
        triggerSubMenuAction="click"
        expandIcon={<HiOutlineDotsVertical size={24} />}
        onClick={confirmExitModal}
        selectedKeys={selectedKeys}
        items={menuOptions.map(({ icon: Icon, ...item }) => ({
          ...item,
          key: item.to,
          title: item.label,
        }))}
      />
    </div>
  );
}
