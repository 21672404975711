export const ENV = 'test';
export const API_BASE_URL = 'https://api.test.greatchildcare.org';

export const AMPLIFY_CONFIG = {
  aws_project_region: 'us-east-1',
  aws_cognito_identity_pool_id: 'us-east-1:61612f65-f65f-42a8-84b4-269c9b422183',
  aws_cognito_region: 'us-east-1',
  aws_user_pools_id: 'us-east-1_kwSGKDc8t',
  aws_user_pools_web_client_id: '4c6n5n5dngqr4nnemqoji6f34u',
  oauth: {},
  federationTarget: 'COGNITO_IDENTITY_POOLS',
  aws_user_files_s3_bucket: 'dev-gcc-uploads-bucket',
  aws_user_files_s3_bucket_region: 'us-east-1',
  aws_content_delivery_bucket: 'dev-gcc-hosting-bucket',
  aws_content_delivery_bucket_region: 'us-east-1',
  aws_content_delivery_url: 'https://d2t1qcgkiw77qg.cloudfront.net',
};
