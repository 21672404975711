import Card from 'components/Card/Card';

const CareLocationDetails = () => {
  return (
    <Card
      style={{
        borderTop: '0px',
      }}
    >
      Care Location Details
    </Card>
  );
};

export default CareLocationDetails;
