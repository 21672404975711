import PropTypes from 'prop-types';
import React, { useRef } from 'react';
import { Col, Input, InputNumber, Typography } from 'antd';
import Form, { MAX_INPUT_PX_WIDTH } from 'components/Form';
import SearchAddress from 'components/SearchAddress';
import { AsyncSelect } from 'components/Select';
import FormItem from 'components/FormItem';
import { US_COUNTRY_CODES } from 'components/PlaceAutoComplete/PlaceAutoComplete';

const { Text } = Typography;

export default function AddressFields({ searchRef, addressKey, required, disableFields, readOnly = false, ...rest }) {
  const localRef = useRef(null);
  const inputRef = searchRef || localRef;
  const form = Form.useFormInstance();
  const isArray = Array.isArray(addressKey);

  const id = isArray ? [...addressKey, 'id'] : [addressKey, 'id'];
  const street = isArray ? [...addressKey, 'street'] : [addressKey, 'street'];
  const street2 = isArray ? [...addressKey, 'street2'] : [addressKey, 'street2'];
  const state = isArray ? [...addressKey, 'state'] : [addressKey, 'state'];
  const city = isArray ? [...addressKey, 'city'] : [addressKey, 'city'];
  const postalCode = isArray ? [...addressKey, 'postalCode'] : [addressKey, 'postalCode'];
  const county = isArray ? [...addressKey, 'county'] : [addressKey, 'county'];
  const lat = isArray ? [...addressKey, 'location', 'lat'] : [addressKey, 'location', 'lat'];
  const lon = isArray ? [...addressKey, 'location', 'lat'] : [addressKey, 'location', 'lon'];
  const description = isArray ? [...addressKey, 'description'] : [addressKey, 'description'];
  const placeId = isArray ? [...addressKey, 'placeId'] : [addressKey, 'placeId'];

  return (
    <div className={`max-w-[${MAX_INPUT_PX_WIDTH}px]`} data-testid={rest['data-testid']}>
      <FormItem name={id} hidden>
        <InputNumber />
      </FormItem>
      <Col xs={24} sm={22} md={15} lg={24}>
        <FormItem
          name={street}
          label={
            <span>
              Address Line 1<br />
              <Text
                type="secondary"
                style={{ fontSize: '12px', textTransform: 'none', wordSpacing: '-1px', marginLeft: '-10px' }}
              >
                Search address in maps
              </Text>
            </span>
          }
          rules={[{ required: required?.street, message: 'Street address is required' }]}
        >
          <SearchAddress
            ref={inputRef}
            country={US_COUNTRY_CODES}
            onSelect={(location) => {
              form.setFieldValue(addressKey, {
                ...location,
                postalCode: location?.zipCode,
                street2: '',
                location: {
                  lat: location?.lat,
                  lon: location?.lng,
                },
              });
              form.validateFields(
                ['country', 'city', 'state', 'postalCode'].map((name) => [
                  isArray ? addressKey.join('_') : addressKey,
                  name,
                ]),
              );
              inputRef.current.update(location?.street || '');
            }}
            disabled={disableFields || readOnly}
            readOnly={readOnly}
            data-testid="addressLine1"
          />
        </FormItem>
        <FormItem name={street2} label="Address Line 2">
          <Input disabled={disableFields || readOnly} readOnly={readOnly} data-testid="addressLine2" />
        </FormItem>
      </Col>
      <Col xs={24} sm={8} md={8}>
        <FormItem name={city} label="City" rules={[{ required: required?.city, message: 'City is required' }]}>
          <Input disabled={disableFields || readOnly} readOnly={readOnly} data-testid="city" />
        </FormItem>
      </Col>
      <Col xs={24} sm={12} md={8}>
        <FormItem name={state} label="State" rules={[{ required: required?.state, message: 'State is required' }]}>
          <AsyncSelect
            name="mailingAddress.state"
            placeholder="State"
            ariaLabel="State"
            disabled={disableFields || readOnly}
            optionsApiUrl="/countries/states/US"
            getOptionLabel={(opt) => opt.name}
            getOptionValue={(opt) => opt.code}
            isOptionSelected={(option, [value]) => option?.code === value}
            onChangeFormatter={(v) => v?.code}
            readOnly={readOnly}
          />
        </FormItem>
      </Col>
      <Col xs={24} sm={12} md={8}>
        <FormItem
          name={postalCode}
          label="Zip Code"
          rules={[
            { required: required?.postalCode, message: 'Zip code is required' },
            {
              pattern: /^\d{5}(-\d{4})?$/,
              message: 'Please enter a valid US zip code',
            },
          ]}
        >
          <Input disabled={disableFields || readOnly} readOnly={readOnly} data-testid="postalCode" />
        </FormItem>
      </Col>
      <Form.Item name={county} hidden>
        <Input />
      </Form.Item>
      <Form.Item label="Latitude" name={lat} hidden>
        <InputNumber disabled={disableFields} />
      </Form.Item>
      <Form.Item label="Longitude" name={lon} hidden>
        <InputNumber disabled={disableFields} />
      </Form.Item>
      <Form.Item name={description} hidden>
        <Input />
      </Form.Item>
      <Form.Item name={placeId} hidden>
        <Input />
      </Form.Item>
      <Form.Item name={id} hidden>
        <InputNumber />
      </Form.Item>
    </div>
  );
}

AddressFields.propTypes = {
  addressKey: PropTypes.string,
  searchRef: PropTypes.any,
  showLatLng: PropTypes.bool,
  disableFields: PropTypes.bool,
  required: PropTypes.object,
};

AddressFields.defaultProps = {
  showSearch: true,
  showLatLng: false,
  disableFields: false,
  required: {
    country: true,
    city: true,
    state: true,
    postalCode: true,
    street: true,
  },
  searchCols: {
    xs: 24,
    sm: 12,
    md: 8,
  },
};
