import { useMemo, useEffect, useCallback, useState } from 'react';
import { Breadcrumb, Button, ConfigProvider, Empty, Input, Tooltip, Typography } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

import { selectActiveRole } from 'features/auth';
import { actions as familyActions } from 'features/family';
import { actions as dashboardActions, selectors as dashboardSelectors } from 'features/dashboard';

import Form, { FormItemLabel } from 'components/Form';
import BatchAssignment from '../Dashboard/BatchAssignment';
import AdvancedSearchForm from '../Dashboard/AdvanceSearchForm';

import { parseFamilyData } from 'utils';
import { DASHBOARD_TYPES, FAMILY_DASHBOARD_COLUMNS, PROVIDER_DASHBOARD_COLUMNS } from 'constants/index';
import Card from 'components/Card/Card';
import Space from 'components/Space/Space';
import { Close, Renew, Search } from '@carbon/icons-react';
import PageMeta from 'components/PageMeta/PageMeta';
import DashboardTable from './DashboardTable';
import ProviderAdvancedSearchForm from './ProviderAdvanceSearchForm';
import { UNASSIGNED_ID } from './AssignedToCell';

const { Title } = Typography;

const PAGE_SIZE = 10;

function Applications({ query, advanced, setAdvancedSearch }) {
  const dispatch = useDispatch();
  const history = useHistory();
  const [form] = Form.useForm();
  const [quickSearchForm] = Form.useForm();
  const searchQuery = Form.useWatch('query', quickSearchForm);
  const [advanceSearchForm] = Form.useForm();

  // Dashboard filters
  const statuses = Form.useWatch('statuses', form);
  const programIds = Form.useWatch('programIds', form);
  const assigned = Form.useWatch('assigned', form) ?? null;
  const assigneeId = Form.useWatch('assigneeId', form) ?? null;
  const highPriorityOnly = Form.useWatch('highPriorityOnly', form) ?? null;

  const activeRole = useSelector(selectActiveRole);
  const roles = useMemo(() => [activeRole], [activeRole]);
  const dashboardState = useSelector(dashboardSelectors.selectDashboardState);

  const fetchTableData = useCallback(
    ({ params, body, url = undefined, method = undefined }) => {
      dispatch(
        dashboardActions.getData.pending({
          params,
          body: body || {},
          url,
          method,
        }),
      );
    },
    [dispatch],
  );

  const [filteredInfo, setFilteredInfo] = useState({});
  const [sorterInfo, setSorterInfo] = useState({});
  const [hasInitiatedAdvancedSearch, setHasInitiatedAdvancedSearch] = useState(false);

  const isFamilyDashbaord = useSelector(dashboardSelectors.selectDashboardType) === DASHBOARD_TYPES.FAMILIES;

  const columns = useMemo(
    () => (isFamilyDashbaord ? FAMILY_DASHBOARD_COLUMNS : PROVIDER_DASHBOARD_COLUMNS),
    [isFamilyDashbaord],
  );

  const apiUrl = useMemo(() => {
    if (query && isFamilyDashbaord) return `/families/app lications/search/quick`;

    return isFamilyDashbaord ? '/families/applications' : '/providers/applications';
  }, [isFamilyDashbaord, query]);

  const onChange = useCallback(
    (pagination, filters, sorter, customParams) => {
      setPageSize(pagination.pageSize);

      setFilteredInfo(filters);
      setSorterInfo(sorter);

      const _newFetchParams = {
        ...pagination,
        page: pagination.current - 1,
      };

      if (sorter) {
        const isNestedValues = Array.isArray(sorter.field);
        const fieldSorter = isNestedValues
          ? sorter.field.reduce((total, current) => `${total}.${current}`)
          : sorter.field;

        if (customParams?.sort) {
          _newFetchParams.sort = customParams.sort;
        } else if (sorter.field && !_newFetchParams.sort) {
          _newFetchParams.sort = `${fieldSorter},${sorter.order === 'ascend' ? 'asc' : 'desc'}`;
        }
      }

      // Add payload to body
      let body = {};

      //Check for advanced search form data
      if (advanced) {
        body = { ...advanceSearchForm.getFieldsValue() };
      }

      if (filters?.status) {
        body.statuses = filters.status;
      }
      if (Array.isArray(filters?.programType) && filters.programType.length !== 0) {
        body.programIds = filters.programType;
      }
      if (Array.isArray(filters?.assignedTo) && filters.assignedTo.length !== 0) {
        if (filters?.assignedTo?.[0] === UNASSIGNED_ID) {
          body.assigned = false;
        } else {
          body.assigneeId = filters.assignedTo?.[0];
        }
      }

      fetchTableData({
        params: { ...dashboardState.params, ..._newFetchParams },
        body,
        url: advanced ? apiUrl : undefined,
        method: advanced || apiUrl === '/providers/applications' ? 'post' : 'get',
      });
    },
    [advanceSearchForm, advanced, apiUrl, dashboardState.params, fetchTableData],
  );

  const state = useMemo(() => {
    return {
      ...dashboardState,
      onChange,
    };
  }, [dashboardState, onChange]);

  const dashboardFilters = useMemo(() => {
    const filters = {};
    if (statuses?.length) filters.statuses = statuses;
    if (programIds?.length) filters.programIds = programIds;
    if (assigned !== undefined) filters.assigned = assigned;
    if (highPriorityOnly !== undefined) filters.highPriorityOnly = highPriorityOnly;
    if (assigneeId) filters.assigneeId = assigneeId;
    return filters;
  }, [assigned, assigneeId, highPriorityOnly, programIds, statuses]);

  const _filtersAreAcitve = useMemo(() => {
    return Object.values(dashboardFilters).filter(Boolean).length > 0;
  }, [dashboardFilters]);

  const [pageSize, setPageSize] = useState(PAGE_SIZE);
  const params = useMemo(
    () => ({
      current: 1,
      size: pageSize,
      ...(query || advanced ? { query } : dashboardFilters),
    }),
    [advanced, dashboardFilters, pageSize, query],
  );
  const fetchData = useCallback(
    (data) => {
      dispatch(
        dashboardActions.getData.pending({
          url: apiUrl,
          params,
          method: advanced || apiUrl === '/providers/applications' ? 'post' : 'get',
          ...data,
        }),
      );
    },
    [advanced, apiUrl, dispatch, params],
  );

  useEffect(() => {
    if (!advanced) {
      fetchData({});
    } else {
      dispatch(dashboardActions.getData.pending({ cancelled: true }));
      dispatch(dashboardActions.setData([]));
      dispatch(dashboardActions.setLoading(false));
      setHasInitiatedAdvancedSearch(false);
    }
  }, [advanced, apiUrl, dispatch, fetchData, params]);

  useEffect(() => {
    return () => {
      dispatch(dashboardActions.toggleBatchAssign(false));
    };
  }, [dispatch]);

  const mapper = useMemo(() => (isFamilyDashbaord ? parseFamilyData : null), [isFamilyDashbaord]);

  const reload = useCallback(async () => {
    fetchData({});
  }, [fetchData]);

  const onSearch = useCallback(
    (query) => {
      if (query) {
        const params = {
          query,
          current: 1,
          size: pageSize,
        };

        const url = isFamilyDashbaord ? `/families/applications/search/quick` : '/providers/applications';

        dispatch(
          dashboardActions.getData.pending({ url, method: isFamilyDashbaord ? 'get' : 'post', params, body: {} }),
        );
        history.push(`${isFamilyDashbaord ? '/families' : '/providers'}?query=${query ?? ''}`);
      } else {
        history.push(`${isFamilyDashbaord ? '/families' : '/providers'}`);
      }
    },
    [dispatch, history, isFamilyDashbaord, pageSize],
  );

  // const batchAssignCount = useMemo(() => state.selectedRowKeys?.length ?? 0, [state.selectedRowKeys]);

  const _noResults = state.data?.length === 0 && !state.loading;
  const filterOrSortingApplied = Object.values(filteredInfo).some((d) => d !== null) || sorterInfo?.order !== undefined;

  const clearAll = useCallback(() => {
    form.resetFields();
    quickSearchForm.resetFields();
    advanceSearchForm.resetFields();
  }, [advanceSearchForm, form, quickSearchForm]);

  return (
    <div className="mt-2 min-h-min flex space-x-4 flex-row items-start">
      {/* {!(query || advanced) && <DashboardFiltersForm form={form} isFamilyDashbaord={isFamilyDashbaord} />} */}

      {advanced && !query && isFamilyDashbaord && (
        <Card
          title="Filters"
          extra={
            _filtersAreAcitve ? (
              <Button
                danger
                icon={<Close size={19} className="ml-[-4px] mr-[-4px]" />}
                data-testid="clear-all-filters"
                onClick={clearAll}
              >
                Clear filters
              </Button>
            ) : undefined
          }
        >
          <AdvancedSearchForm
            form={advanceSearchForm}
            state={state}
            isFamilyDashbaord={isFamilyDashbaord}
            onSubmit={(data) => {
              let body = {};
              const payload = {
                ...data,
                [data.personType || 'applicant']: {
                  ...data.person,
                },
              };
              delete payload.person;
              delete payload.personType;
              body = payload;
              const params = {
                current: 1,
                pageSize: PAGE_SIZE,
              };
              dispatch(dashboardActions.getData.pending({ url: apiUrl, method: 'post', params, body }));
            }}
          />
        </Card>
      )}

      {advanced && !query && !isFamilyDashbaord && (
        <Card className="min-w-[280px] mt-6" noBodyPadding>
          <ProviderAdvancedSearchForm
            form={advanceSearchForm}
            state={state}
            isFamilyDashbaord={isFamilyDashbaord}
            onSubmit={(data) => {
              const params = {
                current: 1,
                size: PAGE_SIZE,
              };
              dispatch(dashboardActions.getData.pending({ url: apiUrl, method: 'post', params, body: data }));
              setHasInitiatedAdvancedSearch(true);
            }}
          />
        </Card>
      )}

      {advanced && !hasInitiatedAdvancedSearch ? (
        <div className="flex justify-center items-center min-w-[50%] min-h-[400px]">
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="Start your advanced search" />
        </div>
      ) : (
        <Card
          className={advanced ? 'min-w-[50%] mt-6' : 'w-full mt-6'}
          noBodyPadding
          title={
            advanced ? (
              <p data-testid="search-heading">
                {_noResults ? (
                  <Space>
                    <span className="text-xs mb-[-1px]">❌</span> No results
                  </Space>
                ) : (
                  <Space size={1}>
                    <span>{state.pagination?.total || 0}</span>result{state.pagination?.total !== 1 ? 's' : ''}
                  </Space>
                )}
              </p>
            ) : state.loading !== true ? (
              <>
                <span>{state.pagination?.total || 0}</span> provider application
                {state.pagination?.total !== 1 ? 's' : ''}
              </>
            ) : null
          }
          extra={
            <Space>
              {/* {!_noResults && (
              <Button
                disabled={batchAssignCount === 0}
                onClick={() => {
                  dispatch(dashboardActions.toggleBatchAssign());
                }}
                data-testid="batch-assign-btn"
              >
                {batchAssignCount > 1 ? 'Batch Assign' : 'Assign'}
              </Button>
            )} */}

              <Tooltip title={filterOrSortingApplied ? 'Removes any sorting or filters' : undefined}>
                <Button
                  icon={<Renew />}
                  disabled={!filterOrSortingApplied}
                  loading={state.loading}
                  onClick={() => {
                    setFilteredInfo({});
                    setSorterInfo({});
                    fetchTableData({ params: { query } });
                  }}
                  data-testid="reload-btn"
                  aria-label="Reload results"
                >
                  Reset table controls
                </Button>
              </Tooltip>
            </Space>
          }
        >
          {!advanced && (
            <Form
              onFinish={({ query }) => onSearch(query.trim?.())}
              initialValues={{ query }}
              form={quickSearchForm}
              className="m-6 flex flex-row justify-start items-end"
            >
              <div className="flex flex-col space-y-3 sm:space-y-0 sm:flex-row sm:items-center sm:space-x-3">
                <Form.Item
                  name="query"
                  className="w-full sm:w-96 mb-0"
                  label={
                    <FormItemLabel
                      title={
                        isFamilyDashbaord ? (
                          'Search by app ID, family ID or sponsor name'
                        ) : (
                          <>Search by provider name or ID</>
                        )
                      }
                      unstyledTitle
                    />
                  }
                >
                  <Input.Search
                    onSearch={() => quickSearchForm.submit()}
                    suffix={
                      <Button
                        onClick={() => {
                          quickSearchForm.setFieldsValue({ query: '' });
                          onSearch('');
                        }}
                        className="-mr-1"
                        style={{
                          display: !searchQuery ? 'none' : undefined,
                        }}
                        shape="circle"
                        icon={<Close />}
                        size="small"
                        type="text"
                        aria-label="Clear search"
                      />
                    }
                    enterButton={
                      <Button type="primary" aria-label="Search by provider name or ID">
                        <Space vCentered>
                          <Search /> Search
                        </Space>
                      </Button>
                    }
                  />
                </Form.Item>
              </div>

              <div className="flex flex-col ml-4">
                <Button type="default" data-testid="advanced-search-button" onClick={() => setAdvancedSearch(true)}>
                  Advanced search
                </Button>
              </div>
            </Form>
          )}

          {state.data && (
            <ConfigProvider
              renderEmpty={() => (
                <Empty
                  image={Empty.PRESENTED_IMAGE_SIMPLE}
                  description={
                    filterOrSortingApplied || searchQuery || advanced
                      ? 'There are no results that match your search. Try clearing filters.'
                      : state.loading
                      ? null
                      : 'No data to show'
                  }
                />
              )}
            >
              <DashboardTable
                onRow={(record) => {
                  return {
                    onClick: () => {
                      if (isFamilyDashbaord) {
                        dispatch(familyActions.setApplication({}));
                        history.push(`/families/${record.householdId}/applications/${record.id}`);
                      } else {
                        history.push(`/providers/applications/${record.id}`);
                      }
                    },
                    // className: 'cursor-pointer',
                  };
                }}
                colKeys={columns}
                state={state}
                isFamilyDashbaord={isFamilyDashbaord}
                mapper={mapper}
                filteredInfo={filteredInfo}
                sorterInfo={sorterInfo}
                rowKey="id"
                hideSelection
                fetchData={fetchData}
              />
            </ConfigProvider>
          )}
        </Card>
      )}

      <BatchAssignment
        state={state}
        roles={roles}
        isFamilyDashbaord={isFamilyDashbaord}
        reload={reload}
        visible={state.showBatchAssign}
        setVisible={() => dispatch(dashboardActions.toggleBatchAssign())}
      />
    </div>
  );
}

export default function ApplicationsDashboard({ match, history }) {
  const { path } = match;
  let { search } = useLocation();
  const dispatch = useDispatch();
  const params = new URLSearchParams(search);
  const query = params.get('query');
  const isFamilyDashbaord = useSelector(dashboardSelectors.selectDashboardType) === DASHBOARD_TYPES.FAMILIES;
  const [advanced, setAdvanced] = useState(false);

  const setAdvancedSearch = (isAdvanced) => {
    setAdvanced(isAdvanced);
    if (isAdvanced) {
      history.push(`${isFamilyDashbaord ? '/families/search' : '/providers/search'}`);
    } else {
      history.push(`${isFamilyDashbaord ? '/families' : '/providers'}`);
    }
  };

  useEffect(() => {
    if (path.startsWith('/families')) {
      dispatch(dashboardActions.setDashboardType(DASHBOARD_TYPES.FAMILIES));
    } else if (path.startsWith('/providers')) {
      dispatch(dashboardActions.setDashboardType(DASHBOARD_TYPES.PROVIDERS));
    }
    if (path.endsWith('/search')) {
      setAdvancedSearch(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, path]);

  return (
    <>
      <PageMeta title={`${isFamilyDashbaord ? 'Family' : 'Provider'} Applications`} />

      {advanced && (
        <Breadcrumb separator=">">
          <Breadcrumb.Item
            onClick={() => setAdvancedSearch(false)}
            className="cursor-pointer underline decoration-primary"
          >
            <span className="text-primary">Provider application queue</span>
          </Breadcrumb.Item>
          {advanced && <Breadcrumb.Item>Advanced search</Breadcrumb.Item>}
        </Breadcrumb>
      )}

      <Title level={1} className={advanced ? 'mt-4' : undefined}>
        {advanced ? 'Advanced search' : 'Provider application queue'}
      </Title>

      <Applications
        query={query}
        isFamilyDashbaord={isFamilyDashbaord}
        advanced={advanced}
        setAdvancedSearch={setAdvancedSearch}
      />
    </>
  );
}
