import Card from 'components/Card/Card';

const BusinessDetails = () => {
  return (
    <Card
      style={{
        borderTop: '0px',
      }}
    >
      Business Details
    </Card>
  );
};

export default BusinessDetails;
